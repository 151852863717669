/* Colors */
:root {
  --color-background: #FBFBFB;
  --color-brand: #E0994B;
  --color-border: #E8E8E8;
  --color-error: #F34D4D;
  --color-primary: #263F63;
  --color-secondary: #5375FA;
  --color-success: #4DF3A4;
  --color-surface: #FFF;
  /* text colors */
  --color-text-primary: #202122;
  --color-text-secondary: #6A6D71;
}

/* Size */
:root {
  --border-radius: 5px;
  --rounded: 50px;
}